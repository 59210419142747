import { Grid } from "@mui/material"
import AccountsTable from "app/components/AccountsTable"

const PartnerAdminDashboard = ({partner, goToAccount}: any) => {
    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <AccountsTable isPartnerAdmin partner={partner} goToAccount={goToAccount} intent='cashin'/>
        </Grid>
        <Grid item xs={12}>
            <AccountsTable isPartnerAdmin partner={partner} goToAccount={goToAccount} intent='cashout'/>
        </Grid>
    </Grid>
}

export default PartnerAdminDashboard;