import { Grid } from "@mui/material";
import NavigationHeader from "app/components/NavigationHeader";
import TerminalTable from "app/components/TerminalTable";
import TransactionSplitTable from "app/components/TransactionSplitTable";
import TransactionsTable from "app/components/TransactionsTable";
import AccountsController from "./Controller";

interface Props {
    partner: any
    account: string
    stack: string[]
    setStack: (value: string[]) => any
}

const AccountTable = ({partner, account, stack, setStack}: Props) => {
    const {
        user,
        isAdmin,
        fetchTotal,
        fetchTransactions,
        fetchTerminals,
        removeTerminalId,
        downloadTransactions,
    } = AccountsController({partner, account});

    return <>
        <NavigationHeader stack={stack} setStack={setStack}>
            <Grid container spacing={2}>
                {user?.attributes?.transaction_split &&
                    <Grid item xs={12}>
                        <TransactionSplitTable partner={partner} user={user}/>
                    </Grid>
                }
                {user?.attributes?.banks?.allbank &&
                    <Grid item xs={12}>
                        <TransactionsTable showBalance showTotal
                            bank                 = "allbank"
                            title                = {isAdmin? "AllBank - P2M": "QRPH"}
                            type                 = "partner"
                            source               = "p2m"
                            partner              = {partner}
                            fetchTotal           = {fetchTotal}
                            fetchTransactions    = {fetchTransactions}
                            downloadTransactions = {downloadTransactions}
                        />
                    </Grid>
                }
                {user?.attributes?.banks?.rbgi &&
                    <Grid item xs={12}>
                        <TransactionsTable showBalance showTotal
                            bank                 = "rbgi"
                            title                = "RBGI"
                            type                 = "partner"
                            partner              = {partner}
                            fetchTotal           = {fetchTotal}
                            fetchTransactions    = {fetchTransactions}
                            downloadTransactions = {downloadTransactions}
                        />
                    </Grid>
                }
                {user?.attributes?.banks?.icash &&
                    <Grid item xs={12}>
                        <TransactionsTable showBalance showTotal
                            bank                 = "icash"
                            title                = "ICash"
                            type                 = "partner"
                            partner              = {partner}
                            fetchTotal           = {fetchTotal}
                            fetchTransactions    = {fetchTransactions}
                            downloadTransactions = {downloadTransactions}
                        />
                    </Grid>
                }
                {user?.attributes?.banks?.gcash &&
                    <Grid item xs={12}>
                        <TransactionsTable showTotal
                            bank                 = "gcash"
                            title                = "GCash"
                            type                 = "partner"
                            partner              = {partner}
                            fetchTotal           = {fetchTotal}
                            fetchTransactions    = {fetchTransactions}
                            downloadTransactions = {downloadTransactions}
                        />
                    </Grid>
                }
                {user?.attributes?.banks?.['gcash-qrph'] &&
                    <Grid item xs={12}>
                        <TransactionsTable showTotal
                            bank                 = "gcash"
                            title                = "GCash QRPH"
                            type                 = "partner"
                            partner              = {partner}
                            fetchTotal           = {fetchTotal}
                            fetchTransactions    = {fetchTransactions}
                            downloadTransactions = {downloadTransactions}
                        />
                    </Grid>
                }
                {user?.attributes?.banks?.jpt && <>
                    {user?.attributes?.jpt?.cash_in && <Grid item xs={12}>
                        <TransactionsTable showTotal
                            bank                 = "jpt"
                            intent               = "cash_in"
                            title                = "Cash In"
                            type                 = "partner"
                            partner              = {partner}
                            fetchTotal           = {fetchTotal}
                            fetchTransactions    = {fetchTransactions}
                            downloadTransactions = {downloadTransactions}
                        />
                    </Grid>}
                    {user?.attributes?.jpt?.cash_out && <Grid item xs={12}>
                        <TransactionsTable showTotal
                            bank                 = "jpt"
                            intent               = "cash_out"
                            title                = "Cash Out"
                            type                 = "partner"
                            partner              = {partner}
                            fetchTotal           = {fetchTotal}
                            fetchTransactions    = {fetchTransactions}
                            downloadTransactions = {downloadTransactions}
                        />
                    </Grid>}
                </>}
                {user?.attributes?.banks?.credit_debit && <>
                    <TransactionsTable showTotal
                        bank                 = "credit-debit"
                        title                = "Credit/Debit Cards"
                        type                 = "partner"
                        partner              = {partner}
                        fetchTotal           = {fetchTotal}
                        fetchTransactions    = {fetchTransactions}
                        downloadTransactions = {downloadTransactions}
                    />
                </>}
                {user?.attributes?.banks?.allbank &&
                    <Grid item xs={12} lg={6}>
                        <TerminalTable
                            bank_code        = "allbank"
                            bank_title       = "AllBank"
                            partner          = {partner}
                            fetchTerminals   = {fetchTerminals}
                            removeTerminalId = {removeTerminalId}
                        />
                    </Grid>
                }
                {user?.attributes?.banks?.rbgi &&
                    <Grid item xs={12} lg={6}>
                        <TerminalTable
                            bank_code        = "rbgi"
                            bank_title       = "RBGI"
                            partner          = {partner}
                            fetchTerminals   = {fetchTerminals}
                            removeTerminalId = {removeTerminalId}
                        />
                    </Grid>
                }
                {user?.attributes?.banks?.icash &&
                    <Grid item xs={12} lg={6}>
                        <TerminalTable
                            bank_code        = "icash"
                            bank_title       = "ICash"
                            partner          = {partner}
                            fetchTerminals   = {fetchTerminals}
                            removeTerminalId = {removeTerminalId}
                        />
                    </Grid>
                }
            </Grid>
        </NavigationHeader>
    </>
}

export default AccountTable;