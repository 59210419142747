import { TableCell, TableRow } from "@mui/material";

const GCashQRPHRow = ({item, isAdmin, amountFormat, computeAmount}: any) => {
    const dateFormat = (date: string) => (
        date?.
        replace(" ", "T").
        replace("Z", "").
        replace(".000000", "")
    )
    const fees = (
        'fixed'      == item.fees.type ? item.fees.value:
        'percentage' == item.fees.type ? +item.amount*item.fees.value/100:
        item.fees.value
    )
    const net = +item.amount-fees
    return (
        isAdmin?
            <TableRow hover>
                <TableCell sx={{whiteSpace: 'pre'}}>{`${item.client}-${item.account}`}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{item.reference_number || '-'}</TableCell>
                <TableCell>{item.transaction_id || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{item.webhook.transaction_id || '-'}</TableCell>
                <TableCell>{item.webhook.reference_id || '-'}</TableCell>
                <TableCell>{item.webhook.instapay_ref?.slice(-6) || '-'}</TableCell>
                <TableCell>{amountFormat(item.amount) || '-'}</TableCell>
                <TableCell>{amountFormat(item.net) || '-'}</TableCell>
                <TableCell>{item.webhook.currency || '-'}</TableCell>
                <TableCell>{item.status || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.created_at) || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.paid_at) || '-'}</TableCell>
            </TableRow>:
            <TableRow hover>
                <TableCell sx={{whiteSpace: 'pre'}}>{item.reference_number || '-'}</TableCell>
                <TableCell>{item.transaction_id || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{item.webhook.transaction_id || '-'}</TableCell>
                <TableCell>{item.webhook.reference_id || '-'}</TableCell>
                <TableCell>{item.webhook.instapay_ref?.slice(-6) || '-'}</TableCell>
                <TableCell>{amountFormat(item.amount) || '-'}</TableCell>
                <TableCell>{amountFormat(net) || '-'}</TableCell>
                <TableCell>{item.webhook.currency || '-'}</TableCell>
                <TableCell>{item.status || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.created_at) || '-'}</TableCell>
                <TableCell sx={{whiteSpace: 'pre'}}>{dateFormat(item.paid_at) || '-'}</TableCell>
            </TableRow>
    )
}

export default GCashQRPHRow;