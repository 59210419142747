export const API_URL = process.env.REACT_APP_API_URL;

export const BASE_URL = ({id, type}: any) => `${API_URL}/${type}/${id}`

export const CHECK_ID                   = ({id, type}: any) => `${BASE_URL({id, type})}/check-id`
export const LOGIN                      = ({id, type}: any) => `${BASE_URL({id, type})}/login`
export const RESET_PASSWORD             = ({id, type}: any) => `${BASE_URL({id, type})}/reset-password`
export const CHECK_RESET_PASSWORD_TOKEN = ({id, type}: any) => `${RESET_PASSWORD({id, type})}/check-token`

export const TRANSACTIONS               = ({id, type, bank, source, query}: any) => `${API_URL}/${type}/${id}/${bank}/${getSource(source)}transactions?${query}`;
export const DOWNLOAD_TRANSACTIONS      = ({id, type, bank, source, query, file_type}: any) => `${API_URL}/${type}/${id}/${bank}/${getSource(source)}download/${file_type}?${query}`;
export const TOTAL                      = ({id, type, bank, source, query}: any) => `${API_URL}/${type}/${id}/${bank}/${getSource(source)}total?${query}`;
export const BALANCE                    = ({id, type, bank, source}: any) => `${API_URL}/${type}/${id}/${bank}/${getSource(source)}balance`;
    
export const ADMIN_URL                     = (id: string) => BASE_URL({id, type: 'admin'});
export const SETTLEMENTS_URL               = (query?: any) => ADMIN_URL('settlements') + (query? `?${query}`: '');
export const ALL_TERMINALS                 = ({id, query}: any) => `${ADMIN_URL(id)}/terminals` + (query? `?${query}`: '');

export const ACCOUNTS_URL                  = (id: string) => `${ADMIN_URL(id)}/accounts`;
export const ACCOUNTS_BANKS                = (id: string) => `${ACCOUNTS_URL(id)}/banks`;
export const ACCOUNTS_BANK                 = ({id, bank_name}: any) => `${ACCOUNTS_URL(id)}/banks/${bank_name}`;
export const ACCOUNTS_TOTAL                = ({id, type, query}: any) => `${BASE_URL({id, type})}/accounts/total?${query}`;
export const ACCOUNT_TOTAL                 = ({id, bank, source, query}: any) => `${ACCOUNTS_URL(id)}/${bank}/${getSource(source)}total?${query}`;
export const ACCOUNT_TRANSACTIONS          = ({id, bank, source, query}: any) => `${ACCOUNTS_URL(id)}/${bank}/${getSource(source)}transactions?${query}`;
export const ACCOUNT_DOWNLOAD_TRANSACTIONS = ({id, bank, source, query, file_type}: any) => `${ACCOUNTS_URL(id)}/${bank}/${getSource(source)}download/${file_type}?${query}`;
export const ACCOUNT_TERMINALS             = ({id, bank, query}: any) => `${ACCOUNTS_URL(id)}/${bank}/terminals?${query}`;
export const ACCOUNT_TERMINAL              = ({id, bank, terminal_id}: any) => `${ACCOUNTS_URL(id)}/${bank}/terminals/${terminal_id}`;
export const ACCOUNTS_USER                 = ({id, username}: any) => `${ACCOUNTS_URL(id)}/${username}`;
export const ACCOUNTS_TRANSACTION_SPLIT    = ({id, username, bank}: any) => `${ACCOUNTS_USER({id, username})}/transaction-split/${bank}`;

export const PARTNER_URL = (id: string) => BASE_URL({id, type: 'partner'});
export const TERMINALS   = ({id, bank, query}: any) => `${PARTNER_URL(id)}/${bank}/terminals` + (query? `?${query}`: '');
export const TERMINAL    = ({id, bank, terminal_id}: any) => `${PARTNER_URL(id)}/${bank}/terminal/${terminal_id}`;

const getSource = (source: string) => source? `${source}/`: ''