import NavigationHeader from "app/components/NavigationHeader";
import { useParams } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import DashboardController from "./controllers/DashboardController";
import PartnerAdminDashboard from "./PartnerAdminDashboard";
import PartnerDashboard from "./PartnerDashboard";

interface Props {
    partner: any
    goToAccount: (account: string) => any
    stack: string[]
    setStack: (value: string[]) => any
}

const Dashboard = ({partner, goToAccount, stack, setStack}: Props) => {

    const {type} = useParams();
    const api = DashboardController();

    return <>
        <NavigationHeader stack={stack} partner={partner} setStack={setStack}>
            {
                'admin' == type?.toLowerCase()?
                <AdminDashboard
                    partner     = {partner}
                    type        = {type}
                    api         = {api}
                    goToAccount = {goToAccount}
                />:
                'partner' == type?.toLowerCase()?
                <PartnerDashboard
                    partner     = {partner}
                    type        = {type}
                    api         = {api}
                />:
                'partner-admin' == type?.toLowerCase()?
                <PartnerAdminDashboard
                    partner     = {partner}
                    type        = {type}
                    api         = {api}
                    goToAccount = {goToAccount}
                />:
                <></>
            }
        </NavigationHeader>
    </>
}

export default Dashboard;